.notification__item {
  box-shadow: none;
  justify-content:center;
}

.notification-container--top-center .notification {
  width: 100% !important;
}
.notification-container--top-center{
  max-width:100%;
  left:0;
  right:0;
}


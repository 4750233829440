*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: var(--fs-16);
  transition: 1s;
}
body {
  font-family: var(--ff-main), sans-serif;
  line-height: 1.2;
  letter-spacing: -0.02em;
  transition: 0.2s ease all;
  -webkit-font-smoothing: antialiased;
  /* user-select: none; */
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
#root {
  display: flex;
  width: 100%;
  height: 100%;
}
.notification-container-top-center {
  width: 65% !important;
  left: 50% !important;
  transform: translate(-50%, 0);
}
.notification-item {
  box-shadow: none !important;
}

.react-pdf__Document {
  max-height: 100% !important;
  max-width: 100% !important;
}
#retryButton {
  margin: 0;
}
#retryButton img {
  min-width: var(--fs-18);
}

#nanorep1 {
  display: none !important;
}
/* .row {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.col {
  flex-basis: 0%;
}

@media (min-width: 36em){
  .row {
    flex-direction: row;
    gap: 0.5em;
  }

  .col {
    flex-basis: 48%;
  }
} */

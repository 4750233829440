/* GRAPHIC */
@font-face {
  font-family: "Graphik-SuperItalic";
  src: url("../fonts/graphik/Graphik-SuperItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-SuperItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-SuperItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Super";
  src: url("../fonts/graphik/Graphik-Super-Web.eot");
  src: url("../fonts/graphik/Graphik-Super-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Super-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-BlackItalic";
  src: url("../fonts/graphik/Graphik-BlackItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-BlackItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-BlackItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Black";
  src: url("../fonts/graphik/Graphik-Black-Web.eot");
  src: url("../fonts/graphik/Graphik-Black-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Black-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-BoldItalic";
  src: url("../fonts/graphik/Graphik-BoldItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-BoldItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-BoldItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Bold";
  src: url("../fonts/graphik/Graphik-Bold-Web.eot");
  src: url("../fonts/graphik/Graphik-Bold-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Bold-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-SemiboldItalic";
  src: url("../fonts/graphik/Graphik-SemiboldItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-SemiboldItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-SemiboldItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("../fonts/graphik/Graphik-Semibold-Web.eot");
  src: url("../fonts/graphik/Graphik-Semibold-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Semibold-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-MediumItalic";
  src: url("../fonts/graphik/Graphik-MediumItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-MediumItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-MediumItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Medium";
  src: url("../fonts/graphik/Graphik-Medium-Web.eot");
  src: url("../fonts/graphik/Graphik-Medium-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Medium-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-RegularItalic";
  src: url("../fonts/graphik/Graphik-RegularItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-RegularItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("../fonts/graphik/Graphik-Regular-Web.eot");
  src: url("../fonts/graphik/Graphik-Regular-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Regular-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-LightItalic";
  src: url("../fonts/graphik/Graphik-LightItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-LightItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-LightItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Light";
  src: url("../fonts/graphik/Graphik-Light-Web.eot");
  src: url("../fonts/graphik/Graphik-Light-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Light-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-ExtralightItalic";
  src: url("../fonts/graphik/Graphik-ExtralightItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-ExtralightItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-ExtralightItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Extralight";
  src: url("../fonts/graphik/Graphik-Extralight-Web.eot");
  src: url("../fonts/graphik/Graphik-Extralight-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Extralight-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-ThinItalic";
  src: url("../fonts/graphik/Graphik-ThinItalic-Web.eot");
  src: url("../fonts/graphik/Graphik-ThinItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-ThinItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik-Thin";
  src: url("../fonts/graphik/Graphik-Thin-Web.eot");
  src: url("../fonts/graphik/Graphik-Thin-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/graphik/Graphik-Thin-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

/*====================================================================*/

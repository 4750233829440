.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker-wrapper {
  display: block;
  height: fit-content;
}
.react-datepicker__current-month {
  display: none;
}
.react-datepicker {
  width: 15.625rem;
  border: var(--onePx) solid rgb(220, 226, 231);
  font-family: var(--ff-semibold), var(--ff-main);
}
.react-datepicker__month-container {
  float: none;
}
.react-datepicker__header {
  padding: 0;
  background-color: #fff;
  border-bottom: none;
}
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  margin: 0.5rem 0.25rem;
}
.react-datepicker__header__dropdown {
  background: rgb(251, 252, 252);
  border-bottom: var(--onePx) solid rgb(220, 226, 231);
  border-radius: 0.25rem 0.25rem 0 0;
  height: 2.6875rem;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  background: rgb(255, 255, 255);
  border: var(--onePx) solid rgb(209, 217, 222);
  border-radius: 0.1875rem;
  height: 1.625rem;
  padding: 0.125rem 0.375rem;
  font-family: var(--ff-medium);
  cursor: pointer;
  color: rgb(21, 24, 27);
  font-size: var(--fs-12);
  font-weight: var(--fw-normal);
  letter-spacing: -0.0156rem;
  line-height: 1.125;
}

.react-datepicker__day-name {
  width: 0.8125rem;
  height: 0.625rem;
  color: rgb(145, 167, 177);
  font-size: var(--fs-12);
  font-family: var(--ff-semibold);
  font-weight: var(--fw-bold);
  text-align: center;
  margin: 0.5rem 0.625rem;
  line-height: inherit;
}
.react-datepicker__month {
  margin: 0.5rem;
}
.react-datepicker__day {
  outline: none;
  width: 2.0625rem;
  height: 1.875rem;
  background: rgb(255, 255, 255);
  border: var(--onePx) solid rgb(223, 227, 232);
  border-radius: 0;
  margin: 0;
  color: rgb(181, 192, 202);
  font-size: var(--fs-12);
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
  text-align: center;
  line-height: 1.875;
}
.react-datepicker__day:hover {
  background: rgb(244, 251, 255);
  border: var(--onePx) solid rgb(1, 142, 255);
  border-radius: 0;
}
.react-datepicker__day--selected {
  background: var(--primary-clr);
  color: #fff;
}
.react-datepicker__day--selected:hover {
  background: var(--primary-clr);
  color: #fff;
  border: var(--onePx) solid var(--primary-clr);
  border-radius: 0;
}
.react-datepicker__navigation {
  width: 1.4375rem;
  height: 1.625rem;
  background: rgb(255, 255, 255);
  border: var(--onePx) solid rgb(209, 217, 222);
  border-radius: 0.1875rem;
  top: 0;
  text-indent: 0;
  color: #d1d9de;
}
.react-datepicker__navigation--next {
  margin: 0.5rem 0.5rem 0.5rem 0.25rem;
  right: 0;
  background: url("../../assets/images/Common/right-angle-arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  padding: 0.625rem;
}
.react-datepicker__navigation--next::after {
  content: "";
}
.react-datepicker__navigation--previous::after {
  content: "";
}
.react-datepicker__navigation--previous {
  margin: 0.5rem 0.25rem 0.5rem 0.5rem;
  left: 0;
  background: url("../../assets/images/Common/left-angle-arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  padding: 0.625rem;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

.react-datepicker__day--disabled {
  background: rgb(255, 255, 255);
  border: var(--onePx) solid rgb(233, 239, 243);
  border-radius: 0;
  color: rgb(231, 234, 238);
}
.react-datepicker__today-button {
  background: rgb(251, 252, 252);
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 0.5rem;
  border-top: var(--onePx) solid rgb(220, 226, 231);
  font-family: var(--ff-main);
  font-size: var(--fs-12);
  color: rgb(21, 24, 27);
}
.react-datepicker__day--in-range {
  background: rgb(244, 251, 255);
  border: var(--onePx) solid rgb(1, 142, 255);
  border-radius: 0;
  color: rgb(1, 142, 255);
}

.react-datepicker__day--in-range:hover {
  background: rgb(244, 251, 255);
  border: var(--onePx) solid var(--primary-clr);
  border-radius: 0;
}
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--today {
  color: #fff;
  background: rgb(1, 142, 255);
  border-radius: 0;
}
/*===================== CUstom Styles ============================ */
#dateRangePicker .react-datepicker__day--range-start,
#dateRangePicker .react-datepicker__day--range-end,
.react-datepicker__day--today {
  color: #fff;
  background: var(--primary-clr);
  border-radius: 0;
}

#dateRangePicker .react-datepicker__day--in-range {
  background: #f4fbef;
  border: var(--onePx) solid var(--primary-clr);
  border-radius: 0;
  color: var(--primary-clr);
}
#dateRangePicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__day:hover {
  background: #f4fbef;
  border: var(--onePx) solid var(--primary-clr);
  border-radius: 0;
  color: var(--primary-clr);
}

.react-datepicker .react-datepicker__day--selected {
  background: var(--primary-clr);
  color: #fff;
}

#dateRangePicker .react-datepicker__day--range-start,
#dateRangePicker .react-datepicker__day--range-end,
#dateRangePicker .react-datepicker__day--today {
  color: #fff;
  background: var(--primary-clr);
}
#dateRangePicker .react-datepicker__day--in-range:hover {
  color: #fff;
  background: var(--primary-clr);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range {
  background-color: var(--light-clr);
  color: var(--dark-clr);
}

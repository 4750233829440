.k-grid {
  margin: 0 !important;
}
.k-grid-table {
  border-collapse: separate !important;
}

.k-grid-header {
  border-bottom-width: 0 !important;
  background: #fff;
}

.k-grid-header > .k-grid-header-wrap > table {
  width: 100% !important;
  border-radius: 0.25em 0.25em 0 0;
  border: none;
}


.plainKendo .k-grid-header .k-header:first-child .k-checkbox {
  right: 0.25em;
  bottom: 0.25em;
}

.k-grid table {
  width: 100% !important;
}
.k-grid thead {
  color: rgb(99, 115, 129) !important;
  font-size: var(--fs-14) !important;
  background: rgb(239, 242, 245) !important;
  font-family: var(--ff-medium) !important;
  font-weight: var(--fw-normal);
}

.k-grid-table tr:first-child td:first-child {
  border-radius: 0.25em 0 0 0;
}
.k-grid-table tr:first-child td:last-child {
  border-radius: 0 0.25em 0 0;
}
.k-grid-table tr:nth-child(odd) {
  background: #f9fafb !important;
}

.plainKendo .k-grid-table tr:nth-child(odd) {
  background: #fff !important;
}

.k-grid-table td {
  box-sizing: border-box;
  color: var(--grey-clr);
  font-family: var(--ff-main);
  font-size: var(--fs-14);
  font-weight: var(--fw-normal);
}

.plainKendo .k-grid-table td {
  color: var(--dark-clr) !important;
  font-size: var(--fs-14);
}

.k-grid-table td:nth-child(2),
.k-grid-table td b {
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
  color: var(--dark-clr);
}

.k-radio {
  border-color: rgb(0 0 0 / 87%);
}
.k-radio:hover,
.k-radio:focus {
  border-color: rgb(0 0 0 / 50%);
}
.k-radio:checked {
  border-color: var(--primary-clr);
  color: #ffffff;
  background-color: var(--primary-clr);
}
.k-grid-header .k-header {
  background: #fff;
  border: none !important;
}

.plainKendo .k-grid-header .k-header {
  padding: 0.5em 0.75em 0 !important;
  border-bottom: 0.125rem solid rgb(151, 151, 151) !important;
  text-align: center;
}

.k-grid tbody > tr {
  background-color: #ffffff !important;
}

.k-grid tbody > tr:first-child {
  border-top: 0 !important;
}
.k-grid tbody > tr > td {
  padding: 1em 1em 1em 0.8em !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0.0625rem solid rgb(227, 234, 237) !important;
  border-bottom: 0.0625rem solid rgb(227, 234, 237) !important;
  margin: 0 0 0.625rem 0 !important;
  color: rgb(99, 115, 129); 
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
}

.plainKendo tbody > tr > td {
  padding: 0.625rem 0.75rem !important;
  color: rgb(21, 24, 27) !important;
  font-size: 1rem !important;
  letter-spacing: -0.0206rem !important;
  line-height: 1.375rem !important;
  text-align: center;
}

.k-grid tbody > tr > td[data-selected] {
  padding: 0 !important;
  padding-left: .75em !important;
}

.k-grid tbody > tr > td:first-child {
  border-left: 0.0625rem solid rgb(227, 234, 237) !important;
}

.plainKendo tbody > tr > td:first-child {
  border: none !important;
}

.plainKendo .k-grid-table tr td:first-child {
  padding: 0.625rem !important;
}

.k-grid tbody > tr > td:last-child {
  border-right: 0.0625rem solid rgb(227, 234, 237) !important;
}

.plainKendo tbody > tr > td:last-child {
  border: none !important;
}

.k-grid-header .k-header > .k-link {
  color: rgb(99, 115, 129);
  font-size: 0.875rem;
  font-family: var(--ff-medium);
  font-weight: 500;
  letter-spacing: -0.0156rem;
  line-height: 1.125rem;
  padding: 0.875rem 0.625rem 0.8125rem 0.5rem;
}

.plainKendo .k-grid-header .k-header > .k-link {
  letter-spacing: -0.0181rem !important;
  line-height: 1rem !important;
}
.k-pager-numbers .k-state-selected {
  z-index: 1;
}
.k-checkbox {
  width: 1.25rem !important;
  height: 1.25rem !important;
  background: rgb(255, 255, 255) !important;
  border: 0.0625rem solid rgb(181, 192, 202) !important;
  border-radius: 0.25rem !important;
}
.k-checkbox:checked {
  width: 1.25rem !important;
  height: 1.25rem !important;
  background: #018eff !important;
  border-radius: 0.25rem !important;
  border: 0.0625rem solid #018eff !important;
}
.k-checkbox:focus {
  box-shadow: none !important;
}

.k-grid-pager {
  background: rgb(239, 242, 245) !important;
}
.k-pager-nav {
  margin: 0 0 0 0.625rem !important;
  background: rgb(255, 255, 255) !important;
  border: 0.0625rem solid rgb(220, 226, 231) !important;
  border-radius: 0.1875rem !important;
}
.k-pager-nav:first-child {
  margin: 0 0 0 0 !important;
}
.k-pager-numbers .k-link:focus,
.k-pager-numbers .k-link.k-state-focus {
  box-shadow: none !important;
}
.k-pager-numbers .k-link {
  color: rgb(123, 142, 152) !important;
  font-family: var(--ff-main) !important;
  font-size: var(--fs-12) !important;
  font-weight: normal !important;
}
.k-pager-numbers .k-link:hover {
  background-color: transparent !important;
}
.k-pager-numbers .k-link.k-state-selected {
  background: #008eff !important;
  border-radius: 0.1875rem !important;
  color: rgb(255, 255, 255) !important;
  font-size: 0.75rem !important;
  font-family: var(--ff-semibold) !important;
  font-weight: 600 !important;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background: #ffffff !important;
}

.k-header .k-icon::before {
  color: rgb(0, 0, 0) !important;
  opacity: 0.3;
  margin-top: 0.75rem;
}
.k-pager-sizes .k-icon::before {
  color: #7b8e98 !important;
  opacity: 1;
  margin-top: 0;
}
.k-i-sort-asc-sm::before {
  content: "\e127";
  margin-top: 0 !important;
}
.k-i-sort-desc-sm::before {
  content: "\e128";
  margin-top: 0 !important;
}
.k-filter-menu .k-columnmenu-actions {
  padding: 24px 1em 1em !important;
}
.k-filter-menu-container .k-icon::before {
  margin-top: 0 !important;
}
.k-columnmenu-item > .k-icon {
  margin-right: 16px;
}
.k-columnmenu-item {
  color: rgb(99, 115, 129);
  font-size: var(--fs-14);
  font-family: var(--ff-medium);
  font-weight: 500;
  letter-spacing: -0.0156rem;
  padding-top: 16px;
}
.k-columnmenu-actions .k-button {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: var(--oneDotFivePx) solid rgb(99, 115, 129);
  color: rgb(99, 115, 129);
  font-family: var(--ff-medium);
  font-size: var(--fs-14);
  font-weight: 500;
  letter-spacing: -0.0156rem;
  line-height: 1;
  text-align: center;
  padding: 8px;
}
.k-columnmenu-actions .k-primary {
  border: var(--oneDotFivePx) solid var(--primary-clr);
  color: #ffffff;
  font-family: var(--ff-medium);
  font-size: var(--fs-14);
  font-weight: 500;
  letter-spacing: -0.0156rem;
  line-height: 1;
  text-align: center;
  padding: 8px;
  background: var(--primary-clr);
  border-radius: 16px;
}
.k-columnmenu-item-content .k-filter-menu .k-filter-menu-container {
  padding: 0.25rem 1rem 1rem;
}
th.k-sorted {
  color: rgb(0, 0, 0) !important;
}
.k-pager-numbers-wrap {
  margin: 0 0 0 0.625rem !important;
}
.k-pager-nav.k-link:focus,
.k-pager-refresh:focus,
.k-state-focus.k-pager-nav.k-link,
.k-state-focus.k-pager-refresh {
  box-shadow: none !important;
}
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background: rgb(255, 255, 255) !important;
  border: 0.0625rem solid rgb(220, 226, 231) !important;
  border-radius: 0.1875rem !important;
}
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap[aria-expanded="true"] {
  background: yellow;
}

.k-pager-input,
.k-pager-sizes,
.k-pager-info {
  color: rgb(123, 142, 152) !important;
  font-size: 0.75rem !important;
  font-family: var(--ffp-main) !important;
  font-weight: normal !important;
  letter-spacing: -0.0156rem !important;
}
.k-grid-content,
.k-grid-content-locked,
.k-pager-wrap {
  border: 0 !important;
}

.k-checkbox:checked:before {
  content: none !important;
}
.k-checkbox:checked::before {
  content: "\f00c" !important;
  font-family: "FontAwesome" !important;
}
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background: #018eff;
}

.k-list .k-item.k-state-selected:hover,
.k-list-optionlabel.k-state-selected:hover {
  background: #018eff;
}
.k-grid tfoot > tr > td {
  border: none !important;
}

.k-grid-header-wrap,
.k-grid-footer-wrap {
  border: none;
}

.k-detail-row {
  background-color: #ffffff !important;
}

/* Badge Styles */
.paid-badge {
  background: rgb(244, 251, 239);
  border-radius: 1rem;
  height: 2rem;
  width: 5.625rem;
  display: inline-block;
  color: rgb(126, 195, 68);
  font-family: var(--ff-main);
  font-size: 0.875rem;
  font-weight: normal;
}
.paid-badge small {
  display: inline-block;
  padding: 0.5625rem 0.3125rem 0.3125rem 1rem;
  font-family: var(--ff-main);
  font-size: 0.875rem;
}
.paid-badge span {
  background: rgba(100, 156, 54, 0.09);
  border-radius: 0 1rem 1rem 0;
  height: 2rem;
  width: 2.5rem;
  display: inline-block;
  text-align: center;
  align-items: center;
  padding-top: 0.1875rem;
}
.badge {
  background: #fff;
  border-radius: 0.375rem;
  height: 2rem;
  color: rgb(0, 0, 0);
  font-family: var(--ff-main);
  font-size: 1rem;
  font-weight: normal;
  padding: 0.3125rem 1rem;
}
.badge.bgred {
  background: rgba(213, 0, 47, 0.06);
}
.badge.bgyellow {
  background: rgba(255, 233, 171, 0.39);
}
.unpaid-badge {
  background: rgb(255, 245, 247);
  border-radius: 1rem;
  height: 2rem;
  width: 6.75rem;
  color: rgb(213, 0, 47);
  font-family: var(--ff-main);
  font-size: 0.875rem;
  font-weight: normal;
  display: inline-block;
  padding: 0.4375rem 1rem;
  line-height: 1.1875rem;
}
.unpaid-badge .ico-unpaid {
  height: 1.0969rem;
}

.k-grid .k-header-column-menu,
.k-grid .k-hierarchy-cell .k-icon {
  display: none;
}
.k-detail-cell {
  padding-inline: 0 !important;
}

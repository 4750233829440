:root {
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-22: 1.375rem;
  --fs-24: 1.5rem;
  --fs-28: 1.75rem;
  --fs-32: 2rem;
  --fs-36: 2.25rem;
  --fs-40: 2.5rem;
  --fs-48: 3rem;
  --fs-64: 4rem;

  --ff-main: Graphik-Regular;
  --ff-medium: Graphik-Medium;
  --ff-bold: Graphik-Bold;
  --ff-semibold: Graphik-Semibold;
  --ff-light: Graphik-Light;
  --ff-italic: Graphik-RegularItalic;
  --ff-black: Graphik-Black;

  --fw-normal: 500;
  --fw-bold: 700;
  --fw-bolder: 900;

  --ls-35: -0.021875rem;
  --accenture-clr: rgb(1, 142, 255);
  --primary-clr: var(--accenture-clr);

  --primary-clr-hs: 207 100%;
  --primary-clr-hue: 207;
  --primary-clr-sat: 100%;
  --primary-clr-lightness: 50%;
  --primary-gradient: linear-gradient(
    -225deg,
    rgb(11, 175, 240) 0%,
    rgb(11, 76, 255) 100%
  );
  --banner-clr-hs: var(--primary-clr-hs);
  --banner-clr-hue: var(--primary-clr-hue);
  --banner-clr-sat: var(--primary-clr-sat);
  --banner-clr-lightness: var(--primary-clr-lightness);
  --banner-gradient: var(--primary-gradient);

  --light-clr: #fff;
  --offwhite-clr: rgb(231, 234, 238);
  --dark-clr: rgb(21, 24, 27);

  --dark-grey-clr: rgb(181, 192, 202);
  --grey-clr: rgb(99, 115, 129);
  --light-grey-clr: rgb(244, 246, 248);

  --disabled-clr: rgb(239, 242, 245);
  --disabled-clr-dark: rgb(181, 192, 202);
  --error-clr: rgb(213, 0, 47);
  --success-clr: rgb(100, 156, 54);
  --warning-clr: rgb(255, 200, 44);
  --dropdown-hover-clr: var(--light-grey-clr);
  --input-border-clr: rgb(221, 221, 221);

  --primary-text-clr: rgb(145, 158, 171);

  --primary-btn-clr: var(--primary-gradient);

  --border-light-1: 0.09375rem solid var(--offwhite-clr);
  --border-primary-1: 0.09375rem solid var(--primary-clr);

  --onePx: 0.0625rem;
  --oneDotFivePx: 0.09375rem;
}

html {
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

/* .k-grid-content::-webkit-scrollbar {
  width: 0.75em;
  height: 0.75em;
} */
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #534a4a5c;
  border-radius: 0.625em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0000005c;
}
/* Hanle on Track hover*/
::-webkit-scrollbar-track:hover {
  background: #f1f1f1;
}

@supports (scrollbar-color: var(--primary-clr)) {
  * {
    scrollbar-color: #534a4a5c transparent;
    scrollbar-width: thin;
  }
}
